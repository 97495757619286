var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c(
        "Card",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "edit-head" }, [
              _c(
                "a",
                { staticClass: "back-title", on: { click: _vm.close } },
                [
                  _c("Icon", { attrs: { type: "ios-arrow-back" } }),
                  _vm._v("返回 "),
                ],
                1
              ),
              _c("div", { staticClass: "head-name" }, [
                _vm._v("流程审批进度历史"),
              ]),
              _c("span"),
              _c(
                "a",
                { staticClass: "window-close", on: { click: _vm.close } },
                [
                  _c("Icon", {
                    staticClass: "ivu-icon-ios-close",
                    attrs: { type: "ios-close", size: "31" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "Row",
            { staticStyle: { position: "relative" } },
            [
              _c("Table", {
                ref: "table",
                attrs: {
                  loading: _vm.loading,
                  border: "",
                  columns: _vm.columns,
                  data: _vm.data,
                },
              }),
              _c("div", { staticClass: "img-title" }, [_vm._v("实时流程图")]),
              _c(
                "Row",
                { staticStyle: { position: "relative" } },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: _vm.imgUrl,
                        expression: "imgUrl",
                      },
                    ],
                  }),
                  _vm.loadingImg
                    ? _c("Spin", { attrs: { size: "large", fix: "" } })
                    : _vm._e(),
                ],
                1
              ),
              _vm.loading
                ? _c("Spin", { attrs: { size: "large", fix: "" } })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }